import {Map} from 'immutable';

/**
 *
 * @param paramData
 * @param callback
 */
export default function openRelatePopup(paramData, callback) {
    this.popup('load');
    setTimeout(() => {
        if (paramData.module == '' || paramData.module == undefined) {
            console.log('není specifikován modul');
            return;
        }
        const restData = {
            limit: 25,
            offset: 0,
            order: [{
                module: paramData.module,
                field: 'date_entered',
                sort: 'asc',
            }],
            filter: {},
        };
        if (paramData.defaultFilter) {
            const actDefaultFilter = {
                operator: 'and',
                operands: [],
            };
            paramData.defaultFilter.forEachObject((e) => {
                const filter = e;
                if (filter.operandType === 'relate' && filter.parentId) {
                    const operand = {
                        module: filter.module,
                        type: 'relate',
                        relationship: filter.relationship,
                        name: filter.name,
                        filter: {
                            operator: 'and',
                            operands: [
                                {field: 'id', type: filter.type, value: filter.parentId},
                            ],
                        },
                    };
                    actDefaultFilter.operands.push(operand);
                } else if (filter.operandType === 'relate' && filter.field) {
                    const operand = {
                        module: filter.module,
                        type: 'relate',
                        relationship: filter.relationship,
                        name: filter.name,
                        filter: {
                            operator: 'and',
                            operands: [{field: filter.field, type: filter.type, value: filter.value}],
                        },
                    };
                    actDefaultFilter.operands.push(operand);
                } else if (filter.operandType !== 'relate') {
                    try {
                        const operand = {
                            field: filter.get('field'),
                            type: filter.get('type'),
                            value: filter.get('value'),
                        };
                        actDefaultFilter.operands.push(operand);
                    } catch (error) {
                        const operand = {
                            field: filter.field,
                            type: filter.type,
                            value: filter.value,
                        };
                        actDefaultFilter.operands.push(operand);
                    }
                }
            });

            if (actDefaultFilter.operands.length !== 0) {
                restData.filter = actDefaultFilter;
            }
        }

        restData.listview_type = 'popup';

        this.rest.post('list/' + paramData.module, restData, (data) => {
            const id = this.getNewPopupId();

            this.dsClear();

            const prefix = 'conf/popup' + id + '/data';

            if (paramData.module == 'ProductTemplates') {
                const akceTree = {
                    type: 'PRODUCTCATEGORIESTREE',
                    content: data.productCatogories,
                    prefix: 'conf/popup' + id + '/data/categories',
                };
                this.dsAddCustom(akceTree);
            }

            const content = this.listContent(data);

            const akce = {
                type: 'PAGELOADLIST',
                page: paramData.module,
                content: content,
                listViewPrefix: prefix + '/listView',
                function: this,
            };
            this.dsAdd('set', prefix + '/listView', {}, 'ListView');
            this.dsAddCustom(akce);
            this.dsAdd('set', prefix + '/listView/type', 'popup');
            this.dsAdd('set', prefix + '/header', this.translate('LBL_MODULE_NAME', paramData.module));
            this.dsAdd('set', prefix + '/callback', callback);
            if (paramData.selectedActive != undefined) {
                this.dsAdd(
                    'set',
                    prefix + '/listView/selectedActive',
                    paramData.selectedActive,
                );
            }
            if (paramData.defaultFilter) {
                this.dsAdd('set', prefix + '/listView/customData/useDefaultFilter', true);

                paramData.defaultFilter.forEachObject((element) => {
                    if (element.operandType != 'relate') {
                        this.dsAdd(
                            'set',
                            prefix + '/listView/defaultFilter/' + element.field,
                            element,
                        );
                    } else {
                        const pomObj = {};
                        pomObj[element.name] = element;
                        this.dsAdd(
                            'set',
                            prefix + '/listView/defaultFilter',
                            Map(pomObj),
                        );
                    }
                });
            }

            if (paramData.contentName) {
                this.dsAdd('set', 'conf/popup' + id + '/content', paramData.contentName);
            } else {
                this.dsAdd('set', 'conf/popup' + id + '/content', 'relateListView');
            }

            if (paramData.customData) {
                paramData.customData.forEachObject((value, key) => {
                    this.dsAdd('set', prefix + '/'+key, value);
                });
            }

            if (paramData?.moreOptions) {
                const moreOptions = paramData.moreOptions.toJS();
                moreOptions.forEachObject((value, key) => {
                    this.dsAdd('set', prefix + '/listView/moreOptions/'+key, value);
                });
            }

            this.dsProcess();
        });
    }, 300);
}
