import detailDefault from '../../detailDefault';

/* eslint-disable-next-line camelcase */
export default class detailacm_transport extends detailDefault {
    load(sAction, data) {
        this.setContactsFilter(sAction, data.prefix);
        this.updateFieldDefs(sAction, data.prefix);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'accounts_acm_transport_1accounts_ida':
                this.setContactsFilter(sAction, data.prefix, data.value.id);
                break;
            case 'contacts_acm_transport_1contacts_ida':
                this.getContactInfo(sAction, data.prefix, data.value.id);
                break;
            case 'kurz':
            case 'cena_bez_dph':
            case 'dph':
            case 'cena_s_dph':
                this.recalculateCurrencyFields(sAction, data.prefix);
                break;
            case 'datum_platby':
                this.updateFieldDefs(sAction, data.prefix);
            case 'currency_id': // eslint-disable-line no-fallthrough
                this.updateConversionRate(sAction, data.prefix);
                break;
            case 'stav':
            case 'acm_purchase_orders_acm_transport_1acm_purchase_orders_ida':
                this.updateFieldDefs(sAction, data.prefix);
                break;
        }
    }

    /**
     * recalculate currency fields
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} sellerId
     */
    recalculateCurrencyFields(sAction, prefix) {
        const exchangeRate = parseFloat(sAction.dataGet(`${prefix}/fields/kurz/value`));
        const basePrice = parseFloat(sAction.dataGet(`${prefix}/fields/cena_bez_dph/value`));
        const vat = basePrice * 0.21;
        const totalPrice = basePrice + vat;
        const basePriceCzk = basePrice * exchangeRate;
        const vatCzk = vat * exchangeRate;
        const totalPriceCzk = totalPrice * exchangeRate;

        sAction.dsClear();
        sAction.dsAdd('set', `${prefix}/fields/cena_bez_dph_czk/value`, basePriceCzk);
        sAction.dsAdd('set', `${prefix}/changes/fields/cena_bez_dph_czk`, basePriceCzk);
        sAction.dsAdd('set', `${prefix}/fields/dph/value`, vat);
        sAction.dsAdd('set', `${prefix}/changes/fields/dph`, vat);
        sAction.dsAdd('set', `${prefix}/fields/dph_czk/value`, vatCzk);
        sAction.dsAdd('set', `${prefix}/changes/fields/dph_czk`, vatCzk);
        sAction.dsAdd('set', `${prefix}/fields/cena_s_dph/value`, totalPrice);
        sAction.dsAdd('set', `${prefix}/changes/fields/cena_s_dph`, totalPrice);
        sAction.dsAdd('set', `${prefix}/fields/cena_s_dph_czk/value`, totalPriceCzk);
        sAction.dsAdd('set', `${prefix}/changes/fields/cena_s_dph_czk`, totalPriceCzk);
        sAction.dsProcess();
    }

    /**
     * update exchange rate
     * @param {sAction} sAction
     * @param {string} prefix
     */
    updateConversionRate(sAction, prefix) {
        const date = sAction.dataGet(`${prefix}/fields/datum_platby/value`) ?? '';
        const currencyId = sAction.dataGet(`${prefix}/fields/currency_id/value`);

        if (currencyId === '-99' || currencyId === -99) {
            sAction.dataSet(`${prefix}/fields/kurz/value`, 1);
            return;
        }

        sAction.load();
        sAction.rest.fetchData('getConversionRate', 'POST', {date, from: currencyId}).then((response) => {
            sAction.dataSet(`${prefix}/fields/kurz/value`, response.data.conversionRate);
            sAction.dataSet(`${prefix}/changes/fields/kurz`, response.data.conversionRate);
            this.recalculateCurrencyFields(sAction, prefix);
            sAction.unLoad();
        });
    }

    /**
     * set default filter for seller contact
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} sellerId
     */
    setContactsFilter(sAction, prefix, sellerId) {
        if (!sellerId) {
            sellerId = sAction.dataGet(`${prefix}/fields/accounts_acm_transport_1accounts_ida/value`);
        }
        if (!sellerId) {
            sAction.dataSet(`${prefix}/fields/contacts_acm_transport_1_name/def/defaultFilter`, []);
            return;
        }
        const defaultFilter = [
            {
                operandType: 'relate',
                parentId: sellerId,
                module: 'Accounts',
                relationship: ['accounts'],
                name: 'accounts_acm_inquired_transporters_1_name',
                relName: 'contacts',
                type: 'eq',
            },
        ];
        sAction.dataSet(`${prefix}/fields/contacts_acm_transport_1_name/def/defaultFilter`, defaultFilter);
    }

    /**
     * handle readonly and required fields
     * @param {sAction} sAction
     * @param {string} prefix
     */
    updateFieldDefs(sAction, prefix) {
        const state = sAction.dataGet(`${prefix}/fields/stav/value`);
        const payment = !!sAction.dataGet(`${prefix}/fields/datum_platby/value`);
        const hasPurchaseOrder = !!sAction.dataGet(`${prefix}/fields/acm_purchase_orders_acm_transport_1acm_purchase_orders_ida/value`);

        sAction.dsClear();
        sAction.dsAdd('set', `${prefix}/fields/currency_id/def/readonly`, ['dodano', 'zruseno'].includes(state));
        sAction.dsAdd('set', `${prefix}/fields/cena_bez_dph/def/readonly`, ['dodano', 'zruseno'].includes(state));
        sAction.dsAdd('set', `${prefix}/fields/kurz/def/readonly`, payment);
        sAction.dsAdd('set', `${prefix}/fields/acm_purchase_orders_acm_transport_1_name/def/readonly`, hasPurchaseOrder);
        sAction.dsProcess();
    }

    /**
     * get contact info
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} contactId
     */
    getContactInfo(sAction, prefix, contactId) {
        sAction.dsClear();
        if (!contactId) {
            sAction.dsAdd('set', `${prefix}/fields/mobil/value`, '');
            sAction.dsAdd('set', `${prefix}/changes/fields/mobil`, '');
            sAction.dsAdd('set', `${prefix}/fields/email/value`, '');
            sAction.dsAdd('set', `${prefix}/changes/fields/email`, '');
            sAction.dsProcess();
            return;
        }

        const searchData = {
            fields: ['id'],
            value: contactId,
            getFields: ['phone_mobile', 'email1'],
            module: 'Contacts',
            limit: 1,
        };

        sAction.load();
        sAction.quickSearch(searchData, (returnData) => {
            sAction.dsAdd('set', `${prefix}/fields/mobil/value`, returnData[0]?.phone_mobile);
            sAction.dsAdd('set', `${prefix}/changes/fields/mobil`, returnData[0]?.phone_mobile);
            sAction.dsAdd('set', `${prefix}/fields/email/value`, returnData[0]?.email1);
            sAction.dsAdd('set', `${prefix}/changes/fields/email`, returnData[0]?.email1);
            sAction.dsProcess();
            sAction.unLoad();
        });
    }
}
