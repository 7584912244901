import detailDefault from '../../detailDefault';

/* eslint-disable-next-line camelcase */
export default class detailacm_budget extends detailDefault {
    load(sAction, data) {
        this.updateFieldDefs(sAction, data.prefix);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'acm_machines_acm_budget_1acm_machines_ida':
                this.updateFieldDefs(sAction, data.prefix, {machineId: data.value.id});
                break;
            case 'acm_devices_acm_budget_1acm_devices_ida':
                this.updateFieldDefs(sAction, data.prefix, {deviceId: data.value.id});
                break;
        }
    }

    /**
     * handle readonly and required fields
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} machineId
     * @param {string} deviceId
     */
    updateFieldDefs(sAction, prefix, {machineId, deviceId} = {}) {
        const hasMachine = machineId ??
            sAction.dataGet(`${prefix}/changes/fields/acm_machines_acm_budget_1acm_machines_ida`) ??
            sAction.dataGet(`${prefix}/fields/acm_machines_acm_budget_1acm_machines_ida/value`);
        const hasDevice = deviceId ??
            sAction.dataGet(`${prefix}/changes/fields/acm_devices_acm_budget_1acm_devices_ida`) ??
            sAction.dataGet(`${prefix}/fields/acm_devices_acm_budget_1acm_devices_ida/value`);

        sAction.dsClear();
        sAction.dsAdd('set', `${prefix}/fields/acm_machines_acm_budget_1_name/def/visible`, !hasDevice);
        sAction.dsAdd('set', `${prefix}/fields/acm_devices_acm_budget_1_name/def/visible`, !hasMachine);
        sAction.dsProcess();
    }
}
