import detailDefault from '../../detailDefault';

export default class detailOpportunities extends detailDefault {
    load(sAction, data) {
        sAction.dsClear();
        const salesStage = sAction.dataGet(data.prefix+'/fields/sales_stage/value');
        if ( salesStage === 'Closed Lost' ||
            salesStage === 'Closed canceled client' ||
            salesStage === 'Closed canceled me'
        ) {
            sAction.dsAdd('set', data.prefix+'/fields/duvod_uzavreni_c/def/readonly', false);
            sAction.dsAdd('set', data.prefix+'/fields/duvod_uzavreni_c/def/required', true);
            sAction.dsAdd('set', data.prefix+'/fields/pripominky_k_uzavreni_c/def/readonly', false);
        } else {
            if (salesStage === 'Closed Won') {
                sAction.dsAdd('set', data.prefix+'/fields/duvod_uzavreni_c/def/readonly', false);
                // sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/visible", false);
            } else {
                sAction.dsAdd('set', data.prefix+'/fields/duvod_uzavreni_c/def/readonly', true);
            }
            sAction.dsAdd('set', data.prefix+'/fields/duvod_uzavreni_c/def/required', false);
        }

        if (sAction.getModuleName() === 'Contacts' && data.prefix !== 'view') {
            const accData = sAction.dataGet('view/fields/account_name');
            const name = accData.get('value');
            const id = accData.getIn(['def', 'id_value']);
            if (id) {
                sAction.dsAdd('set', data.prefix+'/fields/account_name/value', name);
                sAction.dsAdd('set', data.prefix+'/fields/account_name/def/id_value', id);
                sAction.dsAdd('set', data.prefix+'/changes/fields/account_id', id);
            }
        }
        sAction.dsProcess();
        this.setContactsFilter(sAction, data.prefix);
        this.updateFieldDefs(sAction, data.prefix);
    }

    update(sAction, data) {
        if (data.field === 'account_id') {
            const name = sAction.dataGet(data.prefix+'/fields/name/value');
            if (name == null || name === '') {
                const nameAcc = sAction.dataGet(data.prefix+'/fields/account_name/value');
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix+'/fields/name/value', nameAcc);
                sAction.dsAdd('set', data.prefix+'/changes/fields/name', nameAcc);
                sAction.dsProcess();
            }
            this.setContactsFilter(sAction, data.prefix, data.value.id);
        } else if (data.field === 'sales_stage') {
            if ( data.value === 'Closed Lost' ||
                data.value === 'Closed canceled client' ||
                data.value === 'Closed canceled me'
            ) {
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix+'/fields/duvod_uzavreni_c/def/readonly', false);
                sAction.dsAdd('set', data.prefix+'/fields/duvod_uzavreni_c/def/required', true);
                sAction.dsAdd('set', data.prefix+'/fields/pripominky_k_uzavreni_c/def/readonly', false);

                sAction.dsProcess();
            } else {
                sAction.dsClear();
                if (data.value === 'Closed Won') {
                    sAction.dsAdd('set', data.prefix+'/fields/pripominky_k_uzavreni_c/def/readonly', false);
                } else {
                    sAction.dsAdd('set', data.prefix+'/fields/pripominky_k_uzavreni_c/def/readonly', true);
                }

                sAction.dsAdd('set', data.prefix+'/fields/duvod_uzavreni_c/def/required', false);
                sAction.dsProcess();
            }
        } else if (['opportunity_type', 'sales_stage'].includes(data.field)) {
            this.updateFieldDefs(sAction, data.prefix);
        } else if (data.field === 'acm_machines_opportunities_1acm_machines_ida') {
            if (!data.value.id) {
                return;
            }
            sAction.load();
            const fields = {amount: 'sale_price', category: 'machine_type'};
            const searchData = {
                module: 'acm_machines',
                fields: ['id'],
                getFields: Object.values(fields),
                value: data.value.id,
                limit: 1,
            };
            sAction.quickSearch(searchData, (returnData) => {
                sAction.dsClear();
                fields.forEachObject((templateName, lineName) => {
                    sAction.dsAdd('set', `${data.prefix}/fields/${lineName}/value`, returnData[0][templateName]);
                    sAction.dsAdd('set', `${data.prefix}/changes/fields/${lineName}`, returnData[0][templateName]);
                });
                sAction.dsProcess();
                sAction.unLoad();
            });
        }
    }

    /**
     *
     * @param {Object} sAction
     * @param {*} data
     */
    updateDetailLine(sAction, data) {
        const customLines = sAction.dataGet('view/customData/customLines/lines');
        let total = 0;
        switch (data.field) {
            case 'name':
                if (data.value.id) {
                    sAction.load();
                    const fields = {cena_bez_dph_kus: 'list_price', product_categories_id: 'category_id'};
                    const searchData = {
                        module: 'ProductTemplates',
                        fields: ['id'],
                        getFields: Object.values(fields),
                        value: data.value.id,
                        limit: 999999,
                    };
                    sAction.quickSearch(searchData, (returnData) => {
                        sAction.dsClear();
                        fields.forEachObject((templateName, lineName) => {
                            data.saveField(returnData[0][templateName], lineName, true);
                        });

                        const categoryNameSearchData = {
                            module: 'ProductCategories',
                            fields: ['id'],
                            getFields: ['name'],
                            value: returnData[0].category_id,
                            limit: 999999,
                        };
                        if (returnData[0].category_id) {
                            sAction.quickSearch(categoryNameSearchData, (response) => {
                                data.saveField(response[0].name, 'category_name', true);
                                sAction.dsProcess();
                                sAction.unLoad();
                            });
                        } else {
                            console.warn('Category id is missing');
                            sAction.unLoad();
                        }
                    });
                }
                break;
            case 'amount':
            case 'cena_bez_dph_celkem':
            case 'cena_bez_dph_kus':
                customLines.toJS().forEach((line) => {
                    total = total + parseFloat(line.cena_bez_dph_kus * line.amount);
                });
                sAction.dataSet('view/fields/amount/value', total);
                sAction.dataSet('view/changes/fields/amount', total);
                break;
        }
    }

    /**
     * set default filter for seller contact
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} contactId
     */
    setContactsFilter(sAction, prefix, contactId) {
        if (!contactId) {
            contactId = sAction.dataGet(`${prefix}/fields/account_id/value`);
        }
        if (!contactId) {
            sAction.dataSet(`${prefix}/fields/contacts_opportunities_1_name/def/defaultFilter`, []);
            return;
        }
        const defaultFilter = [
            {
                operandType: 'relate',
                parentId: contactId,
                module: 'Accounts',
                relationship: ['accounts'],
                name: 'account_name',
                relName: 'contacts',
                type: 'eq',
            },
        ];
        sAction.dataSet(`${prefix}/fields/contacts_opportunities_1_name/def/defaultFilter`, defaultFilter);
    }

    /**
     * handle readonly and required fields
     * @param {sAction} sAction
     * @param {string} prefix
     */
    updateFieldDefs(sAction, prefix) {
        const type = sAction.dataGet(`${prefix}/fields/opportunity_type/value`);
        const state = sAction.dataGet(`${prefix}/fields/sales_stage/value`);
        const hasQuote = !['Prospecting', 'Qualification'].includes(state);

        sAction.dsClear();
        sAction.dsAdd('set', `${prefix}/fields/category/def/required`, type === 'inquiry');
        sAction.dsAdd('set', `${prefix}/fields/contacts_opportunities_1_name/def/required`, type === 'inquiry');
        sAction.dsAdd('set', `${prefix}/fields/acm_machines_opportunities_1_name/def/required`, type === 'offer');
        sAction.dsAdd('set', `${prefix}/fields/acm_machines_opportunities_1_name/def/readonly`, hasQuote);
        sAction.dsAdd('set', `${prefix}/fields/category/def/readonly`, hasQuote);
        sAction.dsAdd('set', `${prefix}/fields/sales_stage/def/readonly`, hasQuote);
        sAction.dsAdd('set', `${prefix}/timeline/clickable`, !hasQuote);
        sAction.dsProcess();
    }

    /**
     *
     * @param {Object} sAction
     * @param {Object} data
     * @returns {Boolean}
     */
    // pri rucni zmene opportunities.amount neprepocitavat cenu prilezitosti z radku
    // beforeSave(sAction, data) {
    //     const customLines = sAction.dataGet(data.prefix+'/customData/customLines/lines');
    //     if ((customLines && customLines.size === 0) || customLines === undefined) {
    //         return true;
    //     }
    //     let total = 0;
    //     customLines.toJS().forEach((line) => {
    //         total = total + parseFloat(line.cena_bez_dph_celkem);
    //     });
    //     sAction.dataSet(data.prefix+'/changes/fields/amount', total);

    //     return true;
    // }
}
