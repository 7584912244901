import detailDefault from '../../detailDefault';

/* eslint-disable-next-line camelcase */
export default class detailacm_sales_orders extends detailDefault {
    load(sAction, data) {
        this.setContactsFilter(sAction, data.prefix);
        this.updateFieldDefs(sAction, data.prefix);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'accounts_acm_sales_orders_1accounts_ida':
                this.setContactsFilter(sAction, data.prefix, data.value.id);
                break;
            case 'typ':
                this.updateFieldDefs(sAction, data.prefix);
                break;
        }
    }

    /**
     * set default filter for seller contact
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} sellerId
     */
    setContactsFilter(sAction, prefix, sellerId) {
        if (!sellerId) {
            sellerId = sAction.dataGet(`${prefix}/fields/accounts_acm_sales_orders_1accounts_ida/value`);
        }
        if (!sellerId) {
            sAction.dataSet(`${prefix}/fields/contacts_acm_sales_orders_1_name/def/defaultFilter`, []);
            return;
        }
        const defaultFilter = [
            {
                operandType: 'relate',
                parentId: sellerId,
                module: 'Accounts',
                relationship: ['accounts'],
                name: 'accounts_acm_sales_orders_1_name',
                relName: 'contacts',
                type: 'eq',
            },
        ];
        sAction.dataSet(`${prefix}/fields/contacts_acm_sales_orders_1_name/def/defaultFilter`, defaultFilter);
    }

    /**
     * handle readonly and required fields
     * @param {sAction} sAction
     * @param {string} prefix
     */
    updateFieldDefs(sAction, prefix) {
        const lease = sAction.dataGet(`${prefix}/fields/typ/value`) === 'pronajem';

        sAction.dsClear();
        sAction.dsAdd('set', `${prefix}/fields/lease_start/def/required`, lease);
        sAction.dsAdd('set', `${prefix}/fields/lease_end/def/required`, lease);
        sAction.dsAdd('set', `${prefix}/fields/lease_price/def/required`, lease);
        sAction.dsAdd('set', `${prefix}/fields/currency_id/def/required`, lease);
        sAction.dsAdd('set', `${prefix}/tabs/0/panels/1/hidden`, !lease);
        sAction.dsProcess();
    }
}
