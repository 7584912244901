/**
 * @param {object} data
 * @this sAction
 * @TODO rest.post => rest.fetchData
 * @button DetailView/Quotes
 */
export default function quoteActivate(data) {
    const field = {way: data.prefix + '/fields/quote_stage', name: 'quote_stage', value: 'Active'};
    this.saveField(field, true);
    document.getElementById('save_record').click();

    if (this.dataGet(data.prefix+'/canSave')) {
        const opportunityID = this.dataGet(data.prefix + '/fields/opportunity_name/def/id_value');
        if (opportunityID) {
            const data = {
                opportunityID,
                salesStage: 'Value Proposition',
            };
            this.rest.fetchData('opportunityStatusChange', 'POST', data, false);
        }
    }
}
