import detailDefault from '../../detailDefault';

export default class detailProductTemplates extends detailDefault {
    load(sAction, data) {
        this.setProductRelate(sAction, data.prefix);
    }

    update(sAction, data) {
        if (data.field === 'category_id') {
            this.setProductRelate(sAction, data.prefix);
        }
    }

    /**
     * set the correct relate field for the product in catalogue based on category
     * @param {sAction} sAction
     * @param {string} prefix
     */
    setProductRelate(sAction, prefix) {
        const category = sAction.dataGet(`${prefix}/fields/category_name/def/id_value`);

        let relateField = '';
        switch (category) {
            case '9f8c1b52-301c-b638-dc90-66684baa9b97':
                relateField = 'producttemplates_acm_machines_1_name';
                break;
            case 'dfd1e652-fcfe-721a-8cd9-66684beaed57':
                relateField = 'producttemplates_acm_devices_1_name';
                break;
            default:
                relateField = 'description';
                break;
        }

        sAction.dataGet(`${prefix}/tabs`).map((tab, tabIdx) => {
            tab.panels.map((panel, panelIdx) => {
                panel.get('rows').map((row, rowIdx) => {
                    row.fields.map((field, fieldIdx) => {
                        if ([
                            'description',
                            'producttemplates_acm_machines_1_name',
                            'producttemplates_acm_devices_1_name',
                        ].includes(field.get('name'))) {
                            sAction.dataSet(
                                `${prefix}/tabs/${tabIdx}/panels/${panelIdx}/rows/${rowIdx}/fields/${fieldIdx}/name`,
                                relateField,
                            );
                        }
                    });
                });
            });
        });
    }
}
