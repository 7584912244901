import detailDefault from '../../detailDefault';

export default class detailQuotes extends detailDefault {
    load(sAction, data) {
        this.changeLossReasonVisibility(sAction, data);
        const id = sAction.dataGet(`${data.prefix}/id`) ?? '';

        // v ruznych pripadech je id firmy vyplneno na ruznych mistech...
        const accountID = sAction.dataGet(`${data.prefix}/fields/billing_account_name/def/id_value`) ||
            sAction.dataGet(`${data.prefix}/fields/billing_account_id/value`);
        const shouldDownloadAcmPriceLists = sAction.dataGet(`${data.prefix}/fields/should_download_acm_pricelists/value`);

        if ([1, '1'].includes(shouldDownloadAcmPriceLists) || !id) {
            sAction.reloadPriceLists({accountId: accountID, quoteId: ''}, data.prefix);
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/should_download_acm_pricelists/value', false);
            sAction.dsAdd('set', data.prefix + '/changes/fields/should_download_acm_pricelists', false);
            sAction.dsProcess();
        }

        this.setContactsFilter(sAction, data.prefix, accountID);
    }
    update(sAction, data) {
        switch (data.field) {
            case 'danova_sazba':
                sAction.dataSet(data.prefix + '/customData/productData/dph', data.value);
                break;
            case 'currency_id':
                this.changeCurrencyValues(sAction, data);
                break;
            case 'kurz':
                this.exchangeRateUpdate(sAction, data);
                break;
            case 'billing_account_id':
                if (data.value.id) {
                    sAction.reloadPriceLists({accountId: data.value.id, quoteId: sAction.dataGet(`${data.prefix}/id`)}, data.prefix);
                }
                this.setContactsFilter(sAction, data.prefix, data.value.id);
                break;
            case 'cenotvorba_discount':
                this.updateGroups(sAction, data.prefix, data.value,
                    sAction.dataGet(`${data.prefix}/fields/cenotvorba_discount_select/value`),
                    sAction.dataGet(`${data.prefix}/fields/discount_field/value`));
                break;
            case 'cenotvorba_discount_select':
                this.updateGroups(sAction, data.prefix, sAction.dataGet(`${data.prefix}/fields/cenotvorba_discount/value`),
                    data.value, sAction.dataGet(`${data.prefix}/fields/discount_field/value`));
                break;
            case 'discount_field':
                this.updateGroups(sAction, data.prefix, sAction.dataGet(`${data.prefix}/fields/cenotvorba_discount/value`),
                    sAction.dataGet(`${data.prefix}/fields/cenotvorba_discount_select/value`), data.value);
                break;
            case 'quote_stage':
                this.changeLossReasonVisibility(sAction, data);
                break;
        }
    }

    /**
     * Check before save
     * Quote must have at least one product, can't save quote without product (tex, catalog)
     * If some price from quote is under competence price, we can't save quote
     * @param {*} sAction
     * @param {*} data
     * @return {boolean}
     */
    beforeSave(sAction, data) {
        if (sAction.dataGet(`${data.prefix}/isUnderCompetencePrice`)) {
            sAction.error(sAction.translate('LBL_CANT_SAVE_COMPETENCE'));

            return false;
        }

        let groups = sAction.dataGet(data.prefix + '/customData/productData/groups');

        if (groups) {
            groups = groups.toJS();
        }

        if (sAction.deviceType === 'mobile') {
            return true;
        }

        let allowSave = false;
        groups.forEachObject((value) => {
            value['lines'].forEachObject((line) => {
                if (line['name']) {
                    allowSave = true;
                }
            });
        });
        if (!allowSave) {
            sAction.error(sAction.translate('LBL_SAVE_PREVENTED_MISSING_PRODUCT'));
        }

        return allowSave;
    }

    /**
     *
     * @param {*} sAction
     * @param {*} data
     */
    changeCurrencyValues(sAction, data) {
        // prepocitej produkty v nabidce podle zadaneho kurzu...
        if (!data.value && data.prevValue === '-99' || data.value === '-99' && !data.prevValue) {
            return;
        }

        if (!data.value) {
            data.value = '-99';
        }

        let kurz = 0;
        const kurzFieldValue = sAction.dataGet(data.prefix + '/fields/kurz/value');
        if (kurzFieldValue && parseFloat(kurzFieldValue)) {
            kurz = kurzFieldValue;
        }

        sAction.dataSet(data.prefix+'/customData/productData/currency_id', data.value);

        const groups = sAction.dataGet(data.prefix + '/customData/productData/groups');
        sAction.dsClear();
        let groupIndex = 0;
        for (const group of groups) {
            let lineIndex = 0;
            for (const line of group.lines) {
                const listPrice = sAction.currencyConvert(line.list_price, data.prevValue, data.value, kurz);
                const costPrice = sAction.currencyConvert(line.cost_price, data.prevValue, data.value, kurz);
                const priceAfterDiscount = sAction.currencyConvert(line.price_after_discount, data.prevValue, data.value, kurz);
                sAction.dsAdd('set', `${data.prefix}/customData/productData/groups/${groupIndex}/lines/${lineIndex}/list_price`, listPrice);
                sAction.dsAdd('set', `${data.prefix}/customData/productData/groups/${groupIndex}/lines/${lineIndex}/cost_price`, costPrice);
                sAction.dsAdd('set', `${data.prefix}/customData/productData/groups/${groupIndex}/lines/${lineIndex}/price_after_discount`,
                    priceAfterDiscount);

                if ([0, '0', false].includes(line.discount_select)) { // if price is absolute, change it too
                    const discountAmount = sAction.currencyConvert(line.discount_amount, data.prevValue, data.value, kurz);
                    const initialPriceAfterAddDiscount = sAction.currencyConvert(line.initial_price_after_add_discount, data.prevValue,
                        data.value, kurz);
                    const priceAfterAddDiscount = sAction.currencyConvert(line.price_after_add_discount, data.prevValue, data.value, kurz);
                    sAction.dsAdd('set', `${data.prefix}/customData/productData/groups/${groupIndex}/lines/${lineIndex}/discount_amount`,
                        discountAmount);
                    sAction.dsAdd('set',
                        `${data.prefix}/customData/productData/groups/${groupIndex}/lines/${lineIndex}/price_after_add_discount`,
                        priceAfterAddDiscount);
                    sAction.dsAdd('set',
                        `${data.prefix}/customData/productData/groups/${groupIndex}/lines/${lineIndex}/initial_price_after_add_discount`,
                        initialPriceAfterAddDiscount);
                }
                lineIndex++;
            }
            groupIndex++;
        }
        sAction.dsProcess();
    }

    /**
     *
     * @param {*} sAction
     * @param {*} data
     */
    changeLossReasonVisibility(sAction, data) {
        const lossStates = ['Closed Lost', 'Closed canceled client', 'Closed canceled me'];
        if (lossStates.includes(sAction.dataGet(`${data.prefix}/fields/quote_stage/value`))) {
            sAction.dataSet(`${data.prefix}/fields/loss_reason/def/visible`, true);

            return;
        }

        sAction.dataSet(`${data.prefix}/fields/loss_reason/def/visible`, false);
    }

    /**
     * If some price from quote is under competence price, we can not save quote
     *
     * @param {*} sAction
     * @param {*} prefix
     * @param {*} discount
     * @param {*} discountType
     * @param {*} discountField
     */
    updateGroups(sAction, prefix, discount, discountType, discountField) {
        if (discount && discountType && discountField) {
            const groups = sAction.dataGet(`${prefix}/customData/productData/groups`);
            groups.forEach((value, index) => {
                sAction.dsClear();
                sAction.dsAdd('set', `${prefix}/customData/productData/groups/${index}/deal_tot`, discount);
                sAction.dsAdd('set', `${prefix}/customData/productData/groups/${index}/cenotvorba_discount_select`, discountType);
                sAction.dsAdd('set', `${prefix}/customData/productData/groups/${index}/discount_field_select`, discountField);
                sAction.dsProcess();
            });
        }
    }

    /**
     * Multiple renders for correct calculation
     * @param {*} sAction
     * @param {*} data
     */
    exchangeRateUpdate = (sAction, data) => {
        const currencyID = sAction.dataGet(data.prefix+'/fields/currency_id/value');
        if (currencyID && currencyID !== '-99') {
            // kdyz se meni kurz v eurech napr. z 25 Kc na 20 Kc - nabidka se prepocita dle defaultni meny
            sAction.dataSet(data.prefix+'/fields/kurz/value', data.prevValue);

            // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: "-99"});
            sAction.saveField({
                way: data.prefix + '/fields/currency_id',
                name: 'currency_id',
                value: '-99',
            }, true);

            sAction.dataSet(data.prefix+'/fields/kurz/value', data.value);

            // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: currencyID});
            sAction.saveField({
                way: data.prefix + '/fields/currency_id',
                name: 'currency_id',
                value: currencyID,
            }, true);
        }
    };

    /**
     * set default filter for buyer contact
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} sellerId
     */
    setContactsFilter(sAction, prefix, sellerId) {
        if (!sellerId) {
            sellerId = sAction.dataGet(`${prefix}/fields/billing_account_id/value`);
        }
        if (!sellerId) {
            sAction.dataSet(`${prefix}/fields/contacts_quotes_1_name/def/defaultFilter`, []);
            return;
        }
        const defaultFilter = [
            {
                operandType: 'relate',
                parentId: sellerId,
                module: 'Accounts',
                relationship: ['accounts'],
                name: 'billing_account_name',
                relName: 'contacts',
                type: 'eq',
            },
        ];
        sAction.dataSet(`${prefix}/fields/contacts_quotes_1_name/def/defaultFilter`, defaultFilter);
    }
}
