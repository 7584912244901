/* eslint-disable new-cap */
import {Record, List, Map} from 'immutable';
import moment from 'moment';

export const DataConstructors = {};

// _____________________________ GLOBAL SEARCH
DataConstructors.globalSearch = new Record({
    results: new List(),
    searchString: '',
});

// ______________________________ LEAD CONVERT
DataConstructors.leadConvert = new Record({
    detailViews: new List(),
    record: null,
    name: null,
    action: null,
    newBeans: new List(),
    relatedBeans: new List(),
    activities: {},
});

// _____________________________ QUOTE CUSTOM DATA
DataConstructors.productData = new Record({
    groups: new List(),
    currency_id: '-99',
    shipping: null,
    total: null,
    dph: null,
});

DataConstructors.productGroup = new Record({
    id: null,
    name: null,
    type: null,
    description: null,
    bundle_stage: null,
    cenotvorba: '',
    deal_tot: 0.0,
    cenotvorba_discount_select: '1',
    discount_field_select: 'discount_amount',
    lines: new List(),
    lines2: new List(),
    descFocused: false,
    product_category_id: '',
});

DataConstructors.permisisonAccess = new Record({
    roles: new List(),
    users: new List(),
    allowAll: false,
});

DataConstructors.product = new Record({
    id: null,
    name: '',
    quantity: '1',
    cost_price: '',
    list_price: '',
    discount_price: '',
    discount_amount: '',
    discount_select: 1,
    mft_part_num_int: '',
    mft_part_num: '',
    tax_class: '',
    opce_c: 0,
    product_template_id: '',
    category_id: '',
    description: '',
    picture: '',
    descFocused: false,
    price_after_discount: '',
    kompetence_manager: 0,
    kompetence_obchodnik: 0,
    pricing_map: new Map(),
    mj2: '',
    price_mj2: '',
    selected_mj: '',
    mj: 'ks',
    price_after_add_discount: '',
    initial_price_after_add_discount: 0,
    item_type: 'product',
});

DataConstructors.productField = new Record({
    name: '',
    type: '',
    lbl: '',
    disabled: '',
    width: '',
});

DataConstructors.leadConvertCustomData = new Record({
    beanMark: null,
    checked: null,
    create: true,
    required: null,
    boundable: null,
    related_id: null,
    related_name: null,
    customClass: '',
    candidates: new List(),
    allowCopyActivities: false,
    activities: 'ignore',
});

// _____________________________ CONF RECORDS
DataConstructors.SweetSpot = new Record({
    show: false,
    length: 0,
    data: null,
});
DataConstructors.Popup = new Record({
    show: false,
    content: null,
    filePath: null,
    props: null,
    data: new Map(),
});
DataConstructors.User = new Record({
    id: null,
    name: null,
    user_name: null,
    is_admin: null, // pro HomeSetView
    is_superUser: null,
    isAcmAdmin: false,
    canAccessEditView: false,
    loginAs: false,
    records_per_page: null,
    records_per_subpanel: null,
    orders_planner: null,
    email: null,
    currency: null,
    competence_role: null,
    profile_photo: null,
    microservice_url: '',
    microservice_access_token: '',
    microservice_access_token_expires_at: '',
    microservice_access_token_allowed_scopes: [],
});
DataConstructors.offline = new Record({
    status: false,
    counter: 0,
});
DataConstructors.autosave = new Record({
    active: false,
    interval: 60000,
    lastSave: null,
});
DataConstructors.System = new Record({
    systemName: null,
    logo: null,
    headerLogo: null,
});
DataConstructors.conf = new Record({
    view: '',
    page: '',
    load: false,
    language: null,
    availableLanguages: new Map(),
    front_version: null,
    back_version: null,
    softwareName: null,
    recordsPerPage: null,
    opportunities_subpanel: 'new',
    alertMessage: new Map(),
    popup: DataConstructors.Popup(),
    popup2: DataConstructors.Popup(),
    popup3: DataConstructors.Popup(),
    popup4: DataConstructors.Popup(),
    popup5: DataConstructors.Popup(),
    popper: new List(),
    toast: new List(),
    user: DataConstructors.User(),
    system: DataConstructors.System(),
    redirect: null,
    plugins: {},
    shortcutsHelp: false,
    enableServis: null,
    offline: DataConstructors.offline(),
    loggedOut: false,
});
// _____________________________ CONF RECORDS END

// _____________________________ DETAIL RECORDS
DataConstructors.DetailView = new Record({
    acl: new Map(),
    activeTab: 0,
    activeSubpanelTab: 0,
    activities: null,
    attemptedSave: false,
    boundModules: List(),
    buttons: List(),
    canSave: true,
    changes: new Map({
        fields: new Map(),
        files: List(),
        customData: new Map(),
        forceChange: false,
    }),
    customData: null,
    editFieldsPosition: new Map({a: null, b: null}),
    editRecord: false,
    editedFieldsVname: new Map(),
    fields: new Map(),
    id: null,
    imageActions: Map(),
    isUnderCompetencePrice: false,
    mode: 'detail',
    module: null,
    name: null,
    orderLines: List(),
    readonly: false,
    shouldCallHooks: {
        shouldFetchSubpanels: true,
        shouldCallLoadHook: true,
    },
    subpanelsTabs: List(),
    subpanels: List(),
    tableName: '',
    tabs: List(),
    tags: List(),
    timeline: null,
    type: null,
    autosave: DataConstructors.autosave(),
});

DataConstructors.DetailViewRow = new Record({
    fields: new List(),
});

DataConstructors.Field = new Record({
    name: null,
    value: null,
    actEdit: false,
    customStyle: null,
    customClass: null,
    def: null,
    events: new List([]),
    selectForEdit: false,
});
DataConstructors.Tab = new Record({
    id: null,
    name: null,
    type: null,
    identifier: '',
    active: null,
    hidden: false, // pro dynamicke skryvani/odkryvani tabu za pochodu dle aktualne vybranych dat na zaznamu
    error: false, // check if tab contains required field  in save record process
    removable: true, // false = neda se odstranit pres editview
    newRecord: true, // false = nezobrazi se na novem zaznamu
    panels: new List(),
});

DataConstructors.DetailButton = new Record({
    id: null,
    def: new Map(),
});

DataConstructors.Cell = new Record({
    name: '',
    columns: 1,
});

DataConstructors.Event = new Record({key: null, functionName: null});
// _____________________________ DETAIL RECORDS END

// _____________________________ SUBPANEL RECORDS
DataConstructors.subpanelDef = new Record({
    module: null,
    rel_name: null,
    def: new Map({
        show: true,
        open: true,
        load: false,
    }),
    rows: new List(),
    moreOptions: new List(),
});
// _____________________________ SUBPANEL RECORDS END

// _____________________________ LIST RECORDS
DataConstructors.ListView = new Record({modul: null,
    rows: new List(),
    records: new List(),
    orderBy: null,
    asc: null,
    selected: new List(),
    selectedActive: true,
    selectPage: false,
    savedSearch: new List(),
    actSavedSearch: null,
    actFiltering: false,
    openFilter: null,
    openFilterData: null,
    listViewTree: null,
    filter: new List(),
    defaultFilter: new Map(),
    buttons: new List(),
    offset: null,
    rowCount: null,
    rowTotalCount: null,
    type: null,
    limit: null,
    page: 1,
    fieldFunction: new Map(),
    colors: new Map(),
    dateGroupFunction: new Map(),
    dropZoneActive: false,
    prefix: null,
    category: null,
    customData: new Map(),
    moreOptions: new Map(),
    removeFieldText: false,
    hasAssignedUser: true,
    query: '',
});
DataConstructors.ListViewRecord = new Record({
    id: null,
    fields: new List(),
});
DataConstructors.ListViewRecordField = new Record({
    key: null,
    value: null,
    def: null,
    events: [],
});
DataConstructors.ListViewRow = new Record({
    key: null,
    label: null,
    def: null,
    records: null,
    width: null,
    sortable: true,
    disabled: false,
    alterName: null,
    fieldRel: null,
});
DataConstructors.filterParameter = new Record({
    field: null,
    type: null,
    value: null,
    footer: false,
    parent_type: null, // only for fieldy with type parent
    fieldType: null,
    multiValue: false,
    fieldModule: null,
    fieldRel: null,
});
DataConstructors.filterGroup = new Record({
    field: null,
    fieldModule: null,
    fieldRel: null,
    operator: null,
    actCustom: false,
    filters: new List(),
});
DataConstructors.ListMenuButton = new Record({
    id: null,
    def: new Map(),
});
DataConstructors.openFilterData = new Record({
    field: null,
    open: false,
    offset: null,
    def: new Map(),
    fieldRel: null,
});
DataConstructors.listViewTree = new Record({
    module: null,
    name: null,
    field: null,
    relationship: null,
    activeCategory: null,
    tree: null,
});
DataConstructors.listViewTreeCat = new Record({
    id: null,
    name: null,
    type: null,
    children: new List(),
});

DataConstructors.massUpdateData = new Record({
    fields: new Map(),
    selected: new List(),
    module: null,
    changes: new Map({fields: new Map()}),
    prefix: '',
    filterData: null,
});
// _____________________________ LIST RECORDS END

DataConstructors.quoteProductSelector = new Record({
    listView: new DataConstructors.ListView(),
    categories: new List(),
    groupWay: null,
    favorites: new List(),
    recent: new List(),
    top: new List(),

});

// _____________________________ MENU RECORDS
DataConstructors.mainMenuGroup = new Record({
    name: null,
    icon: null,
    open: false,
    buttons: new List(),
});
DataConstructors.mainMenu = new Record({
    groups: [],
    activeModule: null,
    open: true,
    openGroups: new List(),
    actionPanel: null,
});
DataConstructors.menuButton = new Record({
    id: null,
    name: '',
    click: '',
    active: false,
    subMenuLines: null,
    icon: '',
});
// _____________________________ MENU RECORDS END
// _____________________________ CALENDAR RECORDS
DataConstructors.calFilters = new Record({
    filtersDisplayed: (
        localStorage.getItem('acmCalFiltersVisibility') == null ||
        localStorage.getItem('acmCalFiltersVisibility') === 'visible'
    ) ? true : false,
    showMeetings: null,
    showCalls: null,
    showTasks: null,
    showCompleted: null,
    unpackActivities: null,
    meetStates: null,
    callStates: null,
    taskStates: null,
    reloadExternal: false,
});

DataConstructors.calSetting = new Record({
    showMeetings: true,
    showCalls: true,
    showTasks: true,
    weekNumbers: true,
});

DataConstructors.invitees = new Record({
    Users: new List(),
    Contacts: new List(),
    Leads: new List(),
});

DataConstructors.calendarEvent = new Record({
    id: '',
    name: '',
    status: '',
    date_start: '',
    date_end: '',
    act_type: '',
    description: '',
    all_day: false,
});

DataConstructors.calendar = new Record({
    sharedView: null,
    sharedIds: null,
    title: '',
    filters: new DataConstructors.calFilters(),
    setting: new Map(),
    activeTab: 'detailMeeting',
    meetingView: new DataConstructors.DetailView(),
    callView: new DataConstructors.DetailView(),
    taskView: new DataConstructors.DetailView(),
    detailView: new DataConstructors.DetailView(),
    invitees: new Map({relatedInv: new DataConstructors.invitees(), searchInv: new DataConstructors.invitees()}),
    inviteesBackup: new Map({relatedInv: new DataConstructors.invitees(), searchInv: new DataConstructors.invitees()}),
    menuOpen: true,
    eventData: null,
    date_start: null,
    date_end: null,
    gotoDate: null,
    dashletId: null,
    calViewType: '',
    calActiveView: '',
    reload: false,
    updateEvent: null,
    // [PREDEFINED FIELDS] preserve changes over multiple calls of calGetInitData
    preserveChangesAndFieldsOnViews: false,
});
DataConstructors.actionPanelButton = new Record({
    id: '',
    def: null,
});
// _____________________________ CALENDAR RECORDS END
// _____________________________ REPORT WINDOW RECORDS
DataConstructors.reportWindow = new Record({
    main: true,
    modules: new List(),
    savedSearch: new List(),
    selectedSavedSearchId: null,
    metadata: null,
    module: null,
    fields: new List(),
    relate: new List(),
    columns: null,
    orderBy: new Map(),
    alterName: new Map(),
    groupBy: new Map(),
    fieldFunction: new Map(),
    dateGroupFunction: new Map(),
    vname: null,
    group: null,
    actContent: 'filter',
    filterAlert: new Map(),
    activeRelate: {},
    results: null,
    sortedPairs: [],
});
DataConstructors.reportWindowGroup = new Record({
    type: 'group',
    operator: 'and',
    children: new List(),
});
DataConstructors.reportWindowMetadata = new Record({
    id: null,
    name: null,
    description: null,
    cat: null,
    show: null,
    adminOnly: null,
    canEdit: null,
    selectedUsers: null,
    selectedRoles: null,
    type: null,
});
DataConstructors.selectField = new Record({
    type: 'field',
    name: null,
    select: null,
    operator: null,
    value: null,
    parent_type: null,
    def: null,
});
DataConstructors.reportRelateField = new Record({
    type: 'relate',
    module: null,
    def: null,
    select: null,
    fields: new List(),
    relate: new List(),
    group: null,
});
DataConstructors.reportWindowColumns = new Record({
    active: new List(),
    hidden: new List(),
});
DataConstructors.reportWindowColumn = new Record({
    name: null,
    def: new Map(),
});
DataConstructors.reportWindowOrderBy = new Record({
    field: null,
    direction: null,
    module: null,
    priority: null,
    relationship: null,
});
DataConstructors.reportWindowGroupBy = new Record({
    field: null,
    active: null,
    module: null,
    priority: null,
    relationship: null,
});
DataConstructors.reportWindowFieldFunction = new Record({
    field: null,
    function: null,
    module: null,
});
DataConstructors.reportWindowAlterName = new Record({
    field: null,
    vname: null,
    module: null,
});
DataConstructors.reportWindowGroupByDate = new Record({
    field: null,
    groupByDate: null,
    module: null,
    relationship: null,
});
// _____________________________ REPORT WINDOW RECORDS

// _____________________________ HOME RECORDS
DataConstructors.Home = new Record({
    activeId: null,
    tabs: new List(),
});

DataConstructors.HomeTabs = new Record({
    name: null,
    cols: new List(),
});

DataConstructors.HomeColumns = new Record({
    rows: new List(),
});

DataConstructors.HomeColumnsRows = new Record({
    name: null,
    def: new Map({
        type: null,
        show: true,
        reportType: null,
    }),
    data: new Map(),
});

// _____________________________ HOME RECORDS END

DataConstructors.EmailSearch = new Record({
    emailUIAction: '',
    mbox: '',
    ieId: '',
    forceRefresh: false,
    getUnread: undefined,
    name: '',
    from_addr: '',
    to_addrs: '',
    searchDateFrom: '',
    searchDateTo: '',
    assigned_user_name: '',
    assigned_user_id: '',
    data_parent_id_search: '',
    data_parent_name_search: '',

    sort: 'date',
    dir: 'desc',
    start: 0,
    limit: 20,

    id: '',
    simple_search: false,
    body_text: '',

    // dalsi (ne-sugarovske) promenne
    page: 1,
    isImap: '',
    way: '',
});

DataConstructors.Select = new Record({
    label: null,
    value: null,
});

DataConstructors.EmailCompose = new Record({
    to_addrs: '',
    cc_addrs: '',
    bcc_addrs: '',
    name: '',
    parent_type: '', // vztahujici se k
    parent_name: '',
    parent_id: '',
    ccExpanded: false,
    bccExpanded: false,
    description_html: '',
    emailTemplate: '', // vybrany template
    emailTemplates: new List(),
    signatures: new List(), // vsechny dostupne podpisy
    signatureId: null, // vybrany podpis ID
    signature: '', // vybrany podpis obsah
    fromAccounts: new List(),
    account: '', // vybrany email ucet
    filledComposeCache: false,
    uid: '', // draft only
    way: '',
    oldWay: '', // puvodni cesta z ktere se popup oteviral (rightPanel, popupy...)
    updateEmailText: '',
    attachments: Map(),
    replyMode: '', // [reply|replyAll|forward]
    loaded: false,
    send_individually: false,
    disable_recipient_change: false,
    customData: null,
    recordModule: null,
    recordId: null,
    keepSelf: false,
    header: null,
});

// _____________________________ EMAIL RECORDS
// 99% nazvu je prevzato ze sugaru beze zmeny
// aby v tom nebyl binec
DataConstructors.Emails = new Record({
    folders: new List(), // viz DataConstructors.EmailFolders
    foldersIMAP: new List(), // viz DataConstructors.EmailFoldersIMAP
    move: new Map({ // drag and drop akce
        action: null,

        // emails
        emailUids: null,
        sourceIeId: null,
        sourceFolder: null,

        // folders
        folderId: null,
        newParentId: null,
    }),
    selectedFolder: new Map({
        mbox: null,
        ieId: null,
        isImap: null,
        way: null,
    }),
    selectedEmail: null,
    emails: new List(), // viz DataConstructors.EmailList
    activeEmail: new Map(), // viz DataConstructors.EmailDetail
    layout: null,
    view1: null, // aktualni pohled (list, compose, email, reply...)
    view2: null, // aktualni pohled (list, compose, email, reply...)
    advancedSearch: null,
    search: DataConstructors.EmailSearch(), // search data
    compose: DataConstructors.EmailCompose(), // search data
});

// emailove sugar slozky v sidebaru
DataConstructors.EmailFolders = new Record({
    text: null,
    id: null,
    ieId: null,
    mbox: null,
    unseen: null,
    folder_type: null,
    expanded: true,
    children: new List(),

    // vlastni promenne
    hover: null,
    way: null,
    isImap: false,
});

// emailove imap slozky v sidebaru
DataConstructors.EmailFoldersIMAP = new Record({
    text: null,
    id: null,
    ieId: null,
    mbox: null,
    expanded: true,
    children: new List(),
    type: null,
    unseen: null,

    // vlastni promenne
    hover: null,
    way: null,
    isImap: true,
});

// vypis seznamu emailu v dane slozce
DataConstructors.EmailList = new Record({
    flagged: null,
    status: null,
    from: null,
    subject: null,
    description: null,
    date: null,
    uid: null,
    mbox: null,
    ieId: null,
    site_url: null,
    seen: null,
    type: null,
    hasAttach: null,
    to_addrs: null,
    way: null,
    isImap: null, // kvuli lastOpenFolder
});

// aktualni rozkliknuty email
DataConstructors.EmailDetail = new Record({
    type: null,
    uid: null,
    ieId: null,
    name: null,
    from_name: null,
    from_addr: null,
    date_start: null,
    time_start: null,
    message_id: null,
    cc_addrs: null,
    attachments: null,
    attachments_array: null,
    toaddrs: null,
    description: null,
    reply_to_addr: null,
    mbox: null,
    cc: null,
    is_sugarEmail: null,
    seen: null, // v sugaru nebylo, pridano
    way: null,
    parent_type: '', // vztahujici se k
    parent_name: '',
    parent_id: '',
});
// _____________________________ EMAIL RECORDS END

// ___________________________________RIGHT PANEL
DataConstructors.RightPanel = new Record({
    show: false,
    customWidth: null,
    content: null,
    reloadOnClose: false,
    closeOnSave: false,
    data: new Map(),
});
// ___________________________________RIGHT PANEL END

// ___________________________________ NOTIFICATION
DataConstructors.notifications = new Record({
    open: false,
    load: true,
    all: false,
    loadMore: false,
    offset: 0,
    notice: 0,
    limit: 10,
    list: new List(),
});
DataConstructors.notificationsRecord = new Record({
    id: null,
    name: null,
    description: null,
    seen: false,
    notice: false,
    record_name: null,
    parent_module: null,
    parent_record: null,
    notify_date: null,
    type: null,
    url: null,
});
// ___________________________________ NOTIFICATION

// ___________________________________ TIMELINE
DataConstructors.timeline = new Record({
    field: null,
    list: null,
    clickable: false,
    onClick: new Map(),
    titles: new Map(),
    colors: new Map(),
    servisColors: new Map(),
});
// ___________________________________ TIMELINE

// ___________________________________ ADMIN
DataConstructors.admin = new Record({
    sections: new List(),
    customData: new Map(),
});
DataConstructors.adminSections = new Record({
    title: null,
    description: null,
    icon: null,
    rows: new List(),
});
DataConstructors.adminSectionsColumnsRows = new Record({
    title: null,
    description: null,
    icon: null,
    link: null,
});

// ___________________________________ ADMIN END

// ___________________________________ MERGE RECORDS
DataConstructors.mergeRecords = new Record({
    records: new List(),
    def: new Map(),
    filteredDef: new Map(),
    sameValues: new Map(),
    newRecord: new Map(),
    module: null,
});
// ___________________________________ MERGE RECORDS END

// ___________________________________ AKTIVITY TIMELINE
DataConstructors.ActivitiesTimelineView = new Record({
    activities: new Map(),
    status: null,
    module: null,
    id: null,
    records: new List(),
    rows: null,
    inPopup: false,
    order: 'desc',
    format: null,
    limit: 20,
    offset: 0,
    name: null,
    header: null,
    icon: null,
});
// ___________________________________ AKTIVITY TIMELINE END

// ___________________________________ HISTORY

DataConstructors.history = new Record({
    urls: new List(),
});

DataConstructors.common = new Record({
    weekStart: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    weekEnd: moment().day('Friday').format('YYYY-MM-DD'),
    date: moment().format('YYYY-MM-DD'),
});

// ___________________________________ HISTORY END

DataConstructors.AppData = new Record({
    appViewCustomData: new Map(),
    servisCommon: new DataConstructors.common(),
    conf: new DataConstructors.conf(),
    view: null,
    menu: new DataConstructors.mainMenu(),
    calendar: new DataConstructors.calendar(),
    reportWindow: new DataConstructors.reportWindow(),
    rightPanel: new DataConstructors.RightPanel(),
    notifications: new DataConstructors.notifications(),
    history: new DataConstructors.history(),
    actionPanel: {
        buttons: [],
    },
});
