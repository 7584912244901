/**
 * Load right panel for product templates
 * @param {string} way
 */
export default function rightPanelProduct({way}) {
    this.openRightPanel('load');

    const params = {secondView: true};

    const productCategory = this.dataGet(way.slice(0, -6)).product_category_id;
    if (productCategory) {
        params.filter = {
            operator: 'and',
            operands: [{
                module: 'ProductCategories',
                type: 'relate',
                relationship: ['category_link'],
                name: 'category_name',
                filter: {
                    operator: 'and',
                    operands: [{
                        field: 'id',
                        type: 'contArray',
                        value: [productCategory],
                    }],
                },
            }],
        };
    }

    this.dsClear();
    const self = this;
    this.rest.post('list/ProductTemplates', params, function(data) {
        const akceRP = {
            type: 'QUOTERPDATAINIT',
            content: {records: data.specRecords},
            prefix: 'rightPanel/data',
        };

        self.dsAddCustom(akceRP);

        if (productCategory) {
            data.listViewTree.activeCategory = productCategory;
        }

        const content = self.listContent(data);

        const akce = {
            type: 'PAGELOADLIST',
            page: 'ProductTemplates',
            content: content,
            buttons: [],
            function: self,
            listViewPrefix: 'rightPanel/data/listView',
        };
        self.dsAddCustom(akce);
        self.dsAdd('set', 'rightPanel/data/groupWay', way);
        self.dsAdd('set', 'rightPanel/data/listView/type', 'rightPanelQuotes');
        self.dsAdd('set', 'rightPanel/customWidth', '60%');

        self.dsAdd('set', 'rightPanel/content', 'productView');
        self.dsProcess();
        self.afterLoadListView(way);
    });
}
