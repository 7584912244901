import detailDefault from '../../detailDefault';

/* eslint-disable-next-line camelcase */
export default class detailacm_inquired_transporters extends detailDefault {
    load(sAction, data) {
        this.setContactsFilter(sAction, data.prefix);
    }

    update(sAction, data) {
        if (data.field === 'accounts_acm_inquired_transporters_1accounts_ida') {
            this.setContactsFilter(sAction, data.prefix, data.value.id);

            sAction.dataSet(`${data.prefix}/fields/name/value`, data.value.name);
            sAction.dataSet(`${data.prefix}/changes/fields/name`, data.value.name);
        } else if (data.field === 'contacts_acm_inquired_transporters_1contacts_ida') {
            this.getContactInfo(sAction, data.prefix, data.value.id);
        }
    }

    /**
     * set default filter for seller contact
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} sellerId
     */
    setContactsFilter(sAction, prefix, sellerId) {
        if (!sellerId) {
            sellerId = sAction.dataGet(`${prefix}/fields/accounts_acm_inquired_transporters_1accounts_ida/value`);
        }
        if (!sellerId) {
            sAction.dataSet(`${prefix}/fields/contacts_acm_inquired_transporters_1_name/def/defaultFilter`, []);
            return;
        }
        const defaultFilter = [
            {
                operandType: 'relate',
                parentId: sellerId,
                module: 'Accounts',
                relationship: ['accounts'],
                name: 'accounts_acm_inquired_transporters_1_name',
                relName: 'contacts',
                type: 'eq',
            },
        ];
        sAction.dataSet(`${prefix}/fields/contacts_acm_inquired_transporters_1_name/def/defaultFilter`, defaultFilter);
    }

    /**
     * get contact info
     * @param {sAction} sAction
     * @param {string} prefix
     * @param {string} contactId
     */
    getContactInfo(sAction, prefix, contactId) {
        sAction.dsClear();
        if (!contactId) {
            sAction.dsAdd('set', `${prefix}/fields/mobil/value`, '');
            sAction.dsAdd('set', `${prefix}/changes/fields/mobil`, '');
            sAction.dsAdd('set', `${prefix}/fields/email/value`, '');
            sAction.dsAdd('set', `${prefix}/changes/fields/email`, '');
            sAction.dsProcess();
            return;
        }

        const searchData = {
            fields: ['id'],
            value: contactId,
            getFields: ['phone_mobile', 'email1'],
            module: 'Contacts',
            limit: 1,
        };

        sAction.load();
        sAction.quickSearch(searchData, (returnData) => {
            sAction.dsAdd('set', `${prefix}/fields/mobil/value`, returnData[0]?.phone_mobile);
            sAction.dsAdd('set', `${prefix}/changes/fields/mobil`, returnData[0]?.phone_mobile);
            sAction.dsAdd('set', `${prefix}/fields/email/value`, returnData[0]?.email1);
            sAction.dsAdd('set', `${prefix}/changes/fields/email`, returnData[0]?.email1);
            sAction.dsProcess();
            sAction.unLoad();
        });
    }
}
