/**
 * create sales order from quote
 * @param {string} id
 */
export default function salesOrderFromQuote({id}) {
    const params = {action: 'salesOrderFromQuote', record: id};
    this.load();
    this.rest.fetchData('customAction', 'POST', params, false).then((response) => {
        this.href(`#detail/acm_sales_orders/${response.data.record}`);
        this.unLoad();
    }).catch((error) => {
        this.error(error.text);
        this.unLoad();
    });
}
