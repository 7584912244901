/**
 * action for buying demanded device
 * @param {string} id
 */
export default function buyDemandedDevice({id}) {
    const params = {action: 'buyDemandedDevice', record: id};
    this.rest.fetchData('customAction', 'POST', params, false).then((response) => {
        this.href(`#detail/acm_purchase_orders/${response.data.record}`);
    }).catch((error) => {
        this.error(error.text);
    });
}
